<template>
  <div class="how-to-apply" v-if="testi">
    <div class="small-intro bg-img">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
    <div class="schoolarship section">
      <div class="container">
        <h1 class="light mb-lg-4">APPLICATIONS</h1>
      </div>
      <div class="item mb-lg-4">
        <div class="container">
          <h6>SISSA</h6>
          <div class="row mb-lg-4">
            <div class="col-lg-6">
              <h1 class="light text-uppercase">
                {{ testi.scolarship.sissa.howtoapply_scolarship_titolo }}
              </h1>
            </div>
            <div class="col-lg-6">
              <div class="deadline">
                <a class="cta cta-primary" v-if="isNotPast(testi.scolarship.sissa.howtoapply_deadline)" :href="testi.scolarship.sissa.howtoapply_send_application_link" target="_blank">
                  Send your application
                </a>
                <div>
                  <h5>deadline</h5>
                  <h3 class="green">{{ testi.scolarship.sissa.howtoapply_deadline_human }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-md-4 mb-2">
              <h4 class="text-uppercase mb-lg-4">{{ testi.scolarship.sissa.howtoapply_titolo_par_1 }}</h4>
              <p v-html="testi.scolarship.sissa.howtoapply_testo_par_1"></p>
            </div>
            <div class="col-lg-6 col-12 mb-md-4 mb-2">
              <h4 class="text-uppercase mb-lg-4">{{ testi.scolarship.sissa.howtoapply_titolo_par_2 }}</h4>
              <div v-html="testi.scolarship.sissa.howtoapply_testo_par_2"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item mb-lg-4">
        <div class="container">
          <h6 v-if="testi.scolarship.ictp.howtoapply_scolarship_titolo_ictp">ICTP</h6>
          <div class="row mb-lg-4">
            <div class="col-lg-6">
              <h1 class="light text-uppercase">
                {{ testi.scolarship.ictp.howtoapply_scolarship_titolo_ictp }}
              </h1>
            </div>
            <div class="col-lg-6" v-if="testi.scolarship.ictp.howtoapply_scolarship_titolo_ictp"> 
              <div class="deadline">
                    <a class="cta cta-primary" v-if="isNotPast(testi.scolarship.ictp.howtoapply_deadline_ictp)" :href="testi.scolarship.ictp.howtoapply_send_application_link" target="_blank">
                        Send your application
                    </a>
                <div>
                  <h5>deadline</h5>
                  <h3 class="green">{{ testi.scolarship.ictp.howtoapply_deadline_human_ictp }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-md-4 mb-2">
              <h4 class="text-uppercase mb-lg-4">{{ testi.scolarship.ictp.howtoapply_titolo_par_1_ictp }}</h4>
              <p v-html="testi.scolarship.ictp.howtoapply_testo_par_1_ictp"></p>
            </div>
            <div class="col-lg-6 col-12 mb-md-4 mb-2">
              <h4 class="text-uppercase mb-lg-4">{{ testi.scolarship.ictp.howtoapply_titolo_par_2_ictp }}</h4>
              <div v-html="testi.scolarship.ictp.howtoapply_testo_par_2_ictp"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rquirements section">
      <div class="container">
        <h6 class="light">student at mhpc</h6>
        <h1 class="light" style="color:#fff">{{testi.requirements.howtoapply_requirements_titolo_1}}</h1>
        <div class="list">
          <div class="mt-lg-4" v-html="testi.requirements.howtoapply_requirements_testo_1">
          </div>
        </div>
        <div class="inner-section">
           <h1 class="light">{{testi.requirements.howtoapply_requirements_titolo_2}}</h1>
        <div class="list">
          <div class="mt-lg-4 "  v-html="testi.requirements.howtoapply_requirements_testo_2">
          </div>
        </div>
        </div>
        <div class="inner-section ">
          <h1 class="documents green light mb-lg-4 ">{{testi.requirements.howtoapply_requirements_titolo_3}}</h1>
          <div class="buttons-container">
            <div class="" v-for="(document,index) of usefullDocs" :key="index">
              <a v-if="document.title" :href="document.file" target="_blank" :download="document.file">
                <div class="cta-big ">
                  <label class="top mb-lg-4 icon-download-document">{{document.title}}</label>
                  <label class="bottom" v-html="document.description"></label>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq">
      <div class="container">
        <div class="container-title">
          <h1 class="green light">FAQ</h1>
          <h1 class="light">Frequently Asked Question</h1>
        </div>
        <div class="list">
          <div class="item" v-for="(item,index) of faq" :key="index">
            <p class="q" v-html="item.attributes.field_question"></p>
            <p class="a" v-html="item.attributes.field_answer.value"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-else></div>
</template>
<script>
  import IntroPage from '../components/IntroPage.vue'
  export default {
    name: "how-to-apply",
    components: {
      IntroPage
    },
    data: () => {
      return {
        currentPageId: "4125f66b-ce71-4e7d-8da3-2a0ad45d989e",
        testi: '',
        faq:[],
        usefullDocs:[]
      }
    },
    computed:{
    },
    methods:{
      /**
       * @param {string} dateString format yyyy-mm-dd
       * @returns {Boolean} true if the provided date is not past
       */
      isNotPast(dateString){
        var today =  new Date();
        var parsed =  new Date(dateString);
        return today < parsed;
      },
      fetchStaticJson(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/how-to-apply`)
        .then((response) => response.json())
        .then((json) => {
          this.testi = json.data
          this.usefullDocs =json.data.useful_documents
          //console.log('test',this.usefullDocs );
        })
      },
      fetchData(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/faq?include=field_question_answer`, {
          method: "GET",
          mode: "cors",
        })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.faq = json.included
          //console.log(this.faq);
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      }
    },
    mounted() {
      this.fetchStaticJson()
      this.fetchData()
    }
  }
</script>
<style lang="scss" scoped>


  .how-to-apply .small-intro {
    background-size: contain;
    background-position: top right;
    background-image: url("../assets/how-top-apply-intro.png");
  }
  .faq {
    position: relative;
    background-color: #FAFAFA;
    z-index: 1;

    &:before {
      position: absolute;
      content: "";
      z-index: -1;
      background-color: #293446;
      width: 100%;
      height: 100px;
    }

    h1 {
      margin-bottom: 0;
    }

    .container-title {
      background-color: #fff;
      padding: 4rem 5rem;
      margin-bottom: 4rem;
    }

    .item {
      border-bottom: 1px solid #b4b4b4;
      padding: 2rem 0rem 1rem 0rem;
    }

    .q {
      color: #1DC07E;
    }
  }

  @media (max-width:992px) {
    
    .how-to-apply .bg-img{
      background-image: url("../assets/filigrana-mobile.svg");
      background-position: top right;
      background-size: 20%;
    }

    .faq {
      &:before {
        height: 80px;
      }

      .container-title {
        padding: 2rem 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  .schoolarship {
    background-color: #FAFAFA;

    h1 {
      font-size: 1.875rem;
      line-height: 2.2rem;
    }

    h4 {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  
    p {
      color: #ACACAC;
    }

    .cta-primary {
      text-transform: uppercase;
      border: 0px;
      color: #fff;
      font-size: 1rem;
      padding: 9px 26px;
      font-weight: 400;
      max-height: 60px;
      display: inline-flex;
      align-items: center;
    }

    .deadline {
      display: flex;
      justify-content: space-between;

      h5 {
        font-size: 1.25rem;
        text-transform: uppercase;
      }

      h3 {
        color: #1DC07E;
        font-size: 1.875rem;
        font-weight: 300;
        line-height: 15px;
      }
    }

    .row .col-lg-6 {
      padding-right: 80px;
    }
  }

  @media (max-width:1320px) {
    .schoolarship {
      .cta-primary {
        font-size: 0.8rem;
      }

      h5 {
        font-size: 1rem;
      }

      h3 {
        font-size: 1.5rem;
      }

    }

    @media (max-width:992px) {
      .schoolarship {
        .row .col-lg-6 {
          padding-right: 0px;
        }

        .item {
          h1 {
            margin-bottom: 3rem;
          }
          .deadline {
            margin-bottom: 3rem;
            flex-direction: row-reverse;
          }
        }
      }
    }

    @media (max-width:576px) {
      .schoolarship {
        .item {
          h1 {
            font-size: 1.7rem;
            line-height: 2rem;
            margin-bottom: 1.5rem;
          }

          .col-lg-6 {
            padding: 0;
          }

          .deadline {
            flex-direction: column-reverse;
            margin-bottom: 1rem;

            .cta-primary {
              margin: 2rem 0px;
            }

            h5 {
              font-size: 1.1rem;
            }

            h3 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

    .rquirements {
    background-color: #293446;
    .inner-section {
  margin: 5rem 0rem;
}
.cta-big {
  padding: 27px 39px;
  padding-left: 79px;
}
.icon-download-document {
  position: relative;
}
.icon-download-document:before {
    background: url("../assets/icon-download-document.svg");
    width: 35px;
    height: 44px;
    content: "";
    left: -52px;
    background-size: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
    .buttons-container {
      &>div {
        margin: 10px;
        &:empty{
          display: none;
        }
        
      }
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      a{
        height: 100%;
        display: block;
      }
      .cta-big {
        height: 100%;
        width: 520px;
        label.bottom,
        label.top{
          font-size: 1.25rem;
          text-transform: none;
          line-height: 1.9rem;
        }
        label.bottom{
          color: #fff;
        }
      }
    }
    h1 {
      color: #fff;
    }
    p {
      color: #fff;
    }
    ul {
      padding-left: 0;
      margin: 3rem 0;
      li {
        position: relative;
        list-style: none;
        color: #fff;
        padding:1rem 0rem 1rem 4rem;
        &:before{
          transform-origin: center left;
          transform: scale(0.8);
          top:10px;
        }
      }
    }
  }

  @media (max-width:992px) {
      .rquirements {
        .buttons-container {
              display: flex;
              flex-direction: column;
              .cta-big{
                width: 100%;
                margin-top: 3rem;
                label.top{
                  margin-bottom: 1rem;
                }
              }
        }
      }
  }
  @media (max-width:768px) {
.rquirements .buttons-container {
      margin: 0px;
      &>div{
        margin: 0;
      }
      .cta-big{
        margin-top: 1rem;
      }
}
  }
  @media (max-width:576px) {
      .rquirements {
        .icon-download-document:before{
          transform-origin: center;
          transform: scale(0.8) translateY(-50%);
        }
        .list {
          ul {
            margin: 2rem 0;
            li{
            padding: 1rem 0rem 1rem 3rem;
          }
          }
        }
        .buttons-container{
          .cta-big{
                padding: 18px 15px 14px 23px;
    padding-left: 68px;
          }
          .cta-big {
            margin-top: 1rem;
            label.top,
            label.bottom{
              font-size: 1rem;
              line-height: 1.5rem;
            }
          }
        }
      }
      .faq h1 {
    line-height: 31px;
}
  }
</style>